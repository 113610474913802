/* Link */
.Polaris-Link,
.Polaris-Link:hover {
  color: theme("colors.turquoiseDark");
}

.Polaris-Link:focus {
  outline: none;
}

/* DataTable */
.Polaris-DataTable__Cell--header {
  font-size: 13px;
}

.Polaris-DataTable__Cell {
  font-size: 12px;
}

.Polaris-DataTable__Heading svg {
  fill: #919eab;
}

/* Workarount to prevent zoom-in after focusing input */
@media screen and (max-width: 768px) {
  .Polaris-TextField__Input {
    font-size: 16px;
  }
}
