html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 1300px) {
  html {
    font-size: 9px;
  }
}

h1 {
  line-height: 1.35;
}

button:focus {
  outline: none;
}

@media screen and (max-width: 991px) {
  #freshworks-container > div > iframe {
    bottom: 70px !important;
  }

  .ProseMirror {
    font-size: 16px !important;
  }
}
