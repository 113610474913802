.table:global(.Polaris-DataTable__Table) th,
.table:global(.Polaris-DataTable__Table)
  button:not([role="menuitem"], [role="link"]) {
  font-weight: 600 !important;
}

.table :global(.Polaris-DataTable__Cell) {
  /* Reduce space in row */
  padding: 1.2rem;
}

.table :global(tr > .Polaris-DataTable__Cell:first-child) {
  /* Same padding as navbar,summary,filter */
  @apply pl-6;
}

.table :global(.Polaris-DataTable__Heading) {
  /* Fix: parent already has padding */
  padding: 0;
}

.table :global(.Polaris-DataTable__TableRow--pinned .Polaris-DataTable__Cell) {
  @apply bg-lightGray;
}

/* Shadows between pinned rows */
.table
  :global(
    .Polaris-DataTable__TableRow--pinned + .Polaris-DataTable__TableRow--pinned
  ) {
  box-shadow: 0 -2px #dfe3e8;
}

/* Shadows to differ subrows from rows */
.table
  :global(
    .Polaris-DataTable__TableRow--collapsible + .Polaris-DataTable__TableSubRow
  ) {
  box-shadow: inset 0 1px #dfe3e8;
}
.table
  :global(
    .Polaris-DataTable__TableSubRow + .Polaris-DataTable__TableRow--collapsible
  ) {
  box-shadow: 0 -2px 0 0 #e9eef4;
}

.table :global(.Polaris-DataTable__TableSubRowsGroup .Polaris-DataTable__Cell) {
  background-color: rgb(242, 247, 248, 0.9);
}

.table :global(.Polaris-DataTable__TableSubRow .Polaris-DataTable__Cell) {
  background-color: rgb(249, 250, 251, 0.4);
}

.table :global(.Polaris-DataTable__TableRow:hover .Polaris-DataTable__Cell) {
  background-color: rgb(242, 247, 248, 0.4);
}
