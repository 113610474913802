.DataTable :global(.DataTable__navBar) {
  border-bottom-width: 1px;
}

.DataTable :global(.DataTable__item) {
  @apply pt-6;
}

.DataTable :global(.DataTable__item:last-of-type) {
  @apply pb-6;
}
