.tippy-box[data-theme~="unstyled"] {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.tippy-box[data-theme~="unstyled"] .tippy-content {
  padding: 0;
  background-color: transparent;
}

.tippy-box[data-theme~="unstyled"][data-placement^="top"]
  > .tippy-arrow:before {
  border-top-color: transparent;
}

.tippy-box[data-theme~="unstyled"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: transparent;
}

.tippy-box[data-theme~="unstyled"][data-placement^="left"]
  > .tippy-arrow:before {
  border-left-color: transparent;
}

.tippy-box[data-theme~="unstyled"][data-placement^="right"]
  > .tippy-arrow:before {
  border-right-color: transparent;
}

.tippy-box[data-theme~="unstyled"] > .tippy-backdrop {
  background-color: transparent;
}

.tippy-box[data-theme~="unstyled"] > .tippy-svg-arrow {
  fill: transparent;
}
