.box {
  @apply h-4;
  @apply flex;
  @apply rounded-lg;
  @apply overflow-hidden;
}

.green {
  @apply bg-turquoiseGreen;
}

.gray {
  @apply bg-sky;
}

.red {
  @apply bg-redCustom;
}

.darkGray {
  @apply bg-inkCustom;
}

.white {
  @apply white;
}

.dottedGreen {
  @apply bg-turquoiseGreen;
  @apply border-2;
  @apply border-orange;
  @apply border-dotted;
}

.dottedGray {
  @apply bg-sky;
  @apply border-2;
  @apply border-orange;
  @apply border-dotted;
}

.darkGrayDotted {
  @apply bg-inkCustom;
  @apply border-2;
  @apply border-orange;
  @apply border-dotted;
}

.purple {
  background-color: rgb(149, 111, 215);
}
